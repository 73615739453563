//Import moved to twig
//@import "../../fonts/Biotif/Biotif";
//@import "../../fonts/Avenir/Avenir";

$contentBlack: 'Ubuntu', sans-serif;
$contentBold: 'Ubuntu', sans-serif;
$contentMedium: 'Ubuntu', sans-serif;

$titleBlack: 'Ubuntu', sans-serif;
$titleBold: 'Ubuntu', sans-serif;
$titleMedium: 'Ubuntu', sans-serif;
$titleRegular: 'Ubuntu', sans-serif;