.login-wrapper {
    width: 100%;
    height: 100vh;

    .login-container {
        width: 500px;
        background-color: white;
        border: 1px solid #edf2f9;
        box-shadow: rgba($color: #12263f, $alpha: 0.03) 0 12px 24px 0;
        border-radius: 6px;
        text-align: center;

        .error-message {
            color: #ea2b15;
        }

        @media (max-width: 500px) {
            width: 100%;
        }
    }
}
