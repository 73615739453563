html,
body,
#root {
    height: 100%;
}

body {
    font-family: 'Ubuntu', sans-serif !important;

    .container {
        padding-top: 20px;
        padding-bottom: 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    #map {
        width: 100%;
        height: 100%;
        flex: 1;
    }

    .app-wrapper {
        .app-filter {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .app-button {
                margin-left: 16px;
                margin-right: 16px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .add-filter-button button {
                font-size: 28px;
                line-height: 16px;
                border-radius: 999px;
                width: 44px;
                text-align: left;
                padding-left: 14px;
                padding-top: 10px;
                padding-right: 14px;
                padding-bottom: 12px;
                margin-right: 32px;
            }

            .app-date-label {
                display: block;
                text-align: left;
                font-family: 'Ubuntu', sans-serif;
                font-size: 12px;
                color: #0d131d;
                line-height: 1;
                padding-bottom: 4px;
            }

            .app-date-pick {
                padding-top: 4px;
                padding-bottom: 4px;
                margin-right: 8px;
                padding-left: 8px;
                border: 2px solid #dde2ec;
                border-radius: 8px;

                &:focus {
                    border: 2px solid #0079ff;
                    border-radius: 8px;
                    outline: 0;
                }
            }

            .qs-datepicker-container {
                font-family: 'Ubuntu', sans-serif;
            }

            .app-date-pick2 {
                margin-right: 16px;
            }

            .app-filter-editor {
                background-color: white;
                color: #0d131d;
                border: 2px solid #dde2ec;
                border-radius: 8px;
                padding-top: 16px;
                padding-bottom: 16px;
                padding-left: 20px;
                padding-right: 20px;
                z-index: 999;
                box-shadow: rgba(0, 16, 46, 0.12) 0 2px 6px, rgba(0, 0, 0, 0.08) 0 1px 3px;

                &.hidden {
                    visibility: hidden;
                }

                .filter-header {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 16px;

                    .close-filter {
                        justify-self: flex-end;
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                    }

                    .close-filter button {
                        font-size: 28px;
                        transform: rotate(45deg);
                        line-height: 16px;
                        border-radius: 999px;
                        width: 44px;
                        text-align: left;
                        padding-left: 14px;
                        padding-top: 10px;
                        padding-right: 14px;
                        padding-bottom: 12px;
                    }
                }

                .app-filter-main {
                    width: 300px;
                }

                .app-filter-second-checkbox {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                .app-filter-dropdown-second {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                .app-button-filter-confirm {
                    padding-top: 20px;
                    padding-bottom: 16px;
                    margin: auto;
                }
            }
        }
    }

    .input-label {
        padding-bottom: 4px;
    }

    .relative-map {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .relative {
        position: relative;
    }

    .app-content-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.hidden {
            visibility: hidden;
        }
    }

    .app-light-opacity {
        opacity: 33%;
    }

    .app-overview-visibility-hidden {
        visibility: hidden;
    }

    .app-full-opacity-map {
        //  opacity: 100%;
        width: 100%;
        height: 100%;
    }

    .app-light-opacity-map {
        opacity: 33%;
        width: 100%;
        height: 100%;
    }
}

.app-title-text {
    padding-bottom: 20px;
}

.awaiting-selection-text {
    width: 100%;
    text-align: center;
    padding-top: 60px;
}

.measurements-dropdown {
    flex-grow: 1;

    .input-label {
        font-size: inherit;
        margin-bottom: 0.5rem;
    }
}

.create-measurement-button {
    align-self: center;
    margin-left: 16px;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.day-title {
    padding-top: 20px;
    padding-bottom: 16px;
}

.data-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 60px;
    padding-bottom: 20px;

    .rdt_TableBody {
        max-height: 500px;
        overflow-y: auto;
    }

    .bar-wrapper {
        width: 100%;
    }
}

.mesurement-data-loading {
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease 0s, box-shadow 0.2s ease 0s;
    z-index: 99999;
}

@media only screen and (max-width: 1000px) {
    // For mobile phones:
    .data-table {
        display: initial !important;
    }

    .bar-chart {
        height: 500px !important;
    }
}

.logout-text {
    align-self: center;
    margin-top: 24px;
    color: #0d6efd !important;

    :hover {
        color: #0a58ca !important;
    }
}
