@import "../../styles/global";

.tab-list-container {
    // Change
    //font-size: 14px;
    .tablist-content {
        height: 0 !important;
    }

    .tabs {
        position: relative;
        border-bottom: 2px solid $separator;

        .active-border {
            position: absolute;
            width: 0;
            left: 0;
            bottom: -2px;
            border-bottom: 3px solid $primary;

            transition: all .3s;
        }

        .tab-list {
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            padding: 0;
            list-style: none;
            font-family: $contentMedium;

            li {
                padding: 5px 20px;
                float: left;
                color: $light-gray;

                transition: all .3s;

                &:hover {
                    color: $primary-hover;
                    cursor: pointer;
                }

                &.active {
                    color: $primary;
                }
            }
        }
    }

    .tablist-content {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 30px;
    }
}