$primary: #0079ff;          //primary theme color

$primary-hover: #268dff;
$primary-hover-alpha: rgba(0, 121, 255, 0.17);

$primary-active: #0061ed;
$primary-active-alpha: rgba(0, 121, 255, 0.36);

$red: #ff434d;              //error
$yellow: #ffa300;           //warning
$green: #00a150;            //success
$blue: #0079ff;             //info

$red-hover: #ff7179;        //error
$yellow-hover: #ffb93f;     //warning
$green-hover: #3fb87b;      //success
$blue-hover: #268dff;       //info

$red-active: #ed2b34;       //error
$yellow-active: #ed9600;    //warning
$green-active: #009437;     //success
$blue-active: #0061ed;      //info

$red-bg: #F2E0E0;           //error backgrounds
$yellow-bg: #FFF3E0;        //warning backgrounds
$green-bg: #E0F2F1;         //success backgrounds
$blue-bg: #F3F5F9;          //info backgrounds

$red-icon: #FF5252;         //error icon
$yellow-icon: #FF8F00;      //warning icon
$green-icon: #01BFA5;       //success icon
$blue-icon: #0079ff;        //info icon

//Used only on text on colored background
$red-text: #D50000;
$yellow-text: #BF5E0C;
$green-text: #01897B;
$blue-text: #027BF8;

$dark-gray: #0D131D;        //for all title texts
$gray: #3E4B5A;             //for content texts and icons
$light-gray: #919EB4;       //for unselected/disabled or texts with less visibility

$input-border: #DDE2EC;     //used in all inputs
$inactive-fill: #F3F5F9;    //used in inactive search
$separator: #E7EBF0;        //used in all content separators

$sidebar-background: #F3F5F9;
$sidebar-select: #E2E8F4;
$sidebar-select-hover: rgba(226, 232, 244, 0.33);
$sidebar-select-active: #dce2ee;
$sidebar-separator: #E6E8EB;

$input-border-hover: #c9ced7;

$light-blue-icon: #C1E2F7;  //used for icon on blue background

$input-copy-fill: #F6F8FB;
$white-hover: #FBFBFB;

$switch-disabled: rgba(0, 0, 0, .24);

//Used in console
$red-console: #BE0001;
$yellow-console: #BB771B;
$green-console: #0F9D58;
$blue-console: #0D8687;