@import "../../styles/global";

.select-container {
    font-family: $contentMedium;
    font-size: 14px;
    line-height: 1.8;
    cursor: pointer;

    &.has-left-icon > .select__control > .select__value-container {
        padding-left: 34px !important;
    }
}

.select__control {
    padding: 3px 14px 2px;
    font-size: 14px;
    line-height: 1.8;
    border-color: $input-border;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: $full-rounding !important;
    box-shadow: 0 0 0 white !important;
    width: 100%;
    //height: 44px;
    color: $dark-gray;
    cursor: pointer !important;
    font-family: $contentMedium;
    transition: all 0.2s ease 0s;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $light-gray;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $light-gray;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $light-gray;
    }

    &:not(.error){
        border-color: $input-border;
    }
}

.select-container.error > .select__control {
    border-color: $red !important;
}

.select__control--is-focused {
    outline: 0;
    border-color: $primary !important;
    border-width: 2px !important;
}

.select__option {
    font-size: 14px;
    font-family: $contentMedium;
}

.select__option:not(.select__option--is-disabled) {
    cursor: pointer !important;
    transition: all 0.2s ease 0s;

    &:active {
        background-color: $primary-active !important;
    }
}

.select__option:not(.select__option--is-selected):not(.select__option--is-disabled) {
    &:hover {
        background-color: $primary-hover-alpha !important;
    }

    &:active {
        background-color: $primary-active-alpha !important;
    }
}

.select__option--is-focused {
    background-color: white !important;
}

.select__option--is-selected {
    background-color: $primary !important;
}

.dropdown-left-icon {
    position: absolute;
    color: $gray;
    font-size: 22px;
    left: 18px;
    top: 32px;
    display: inline-flex;
    vertical-align: middle;
    pointer-events: none;
}

.dropdown-chevron-icon {
    position: absolute;
    color: $gray;
    font-size: 16px;
    right: 16px;
    top: 34px;
    pointer-events: none;
}