@import "../../styles/global";

.data-table {
    & > div {
        overflow: unset !important;
    }

    .rdt_TableHeadRow {
        border-top: none;
        border-bottom: 1px solid $separator;

        * {
            color: $dark-gray;
            font-family: $contentBlack;
        }

        .rdt_TableCol_Sortable {
            position: relative;

            & > span {
                position: absolute;
                height: 14px;
                margin-top: -2px;
                right: -16px;
                color: $primary;
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            border-top: none;
            border-bottom: 1px solid $separator;

            transition: all .3s;
        }

        .rdt_TableCell {
            color: $gray;

            * {
                color: $gray;
        //        font-family: 'Avenir LT Std 85 Heavy';
            }

            i {
                font-size: 22px;
                display: flex;

                &.color-success {
                    color: $green;
                }

                &.color-warning {
                    color: $yellow;
                }

                &.color-error {
                    color: $red;
                }
            }
        }
    }

    &.clickable {
        .rdt_TableRow {

            &:hover {
                cursor: pointer;
                border-bottom-color: transparent;
                box-shadow: rgba(0, 0, 0, .04) 0 4px 10px, rgba(0, 0, 0, .04) 0 1px 3px;
            }
        }
    }
}